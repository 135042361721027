import * as React from 'react'
import { Flex } from 'grid-styled'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import { Helmet } from 'react-helmet'
import Hero from '../../components/Hero'
import Button from '../../components/Button'
import BackgroundSection from '../../components/BackgroundSection'
import MainLayout from '../../components/Layout'
import { AboutContact } from '..'
import {
  BeforeAfter,
  StyledTabs,
  StyledTabList,
  StyledTab,
  Content
} from '../../components/procedures'
import { TabPanel } from 'react-tabs'

const content = {
  backgroundTitle: 'Other Breast Options',
  subtitle: `Trusted, Experienced & Ethical. Boutique plastic surgery on Sydney's North Shore.`,
  title: 'Breast Reduction',
  otherOptions: [
    {
      text: 'Breast Augmentation',
      to: '/procedures/breast-augmentation'
    },
    {
      text: 'Breast Reconstruction',
      to: '/procedures/breast-reconstruction'
    },
    {
      text: 'Breast Reduction',
      to: '/procedures/breast-reduction'
    }
  ]
}

export default ({ data }) => (
  <MainLayout header="light">
    <Helmet>
      <title>Breast Reduction in Sydney - Dr. Graham Sellars</title>
      <meta
        name="description"
        content="Dr. Graham Sellars performs breast reduction surgeries in Sydney, NSW. Improve your figure, silhouette, and posture with this cosmetic procedure."
      />
    </Helmet>

    <Hero bgImage={data.womanWaterfall.childImageSharp} height="600px">
      <h1>{content.title}</h1>
      <p>{content.subtitle}</p>
      <Flex>
        <Button color="#fff" small={true} outline={true}>
          Learn more
        </Button>
      </Flex>
    </Hero>

    <Content>
      <h2>Breast Reduction</h2>
      <h4>
        Breast Reduction is often perceived as a simple operation, but
        considerable skill is required to achieve a natural breast shape.
      </h4>
      <p>
        Breast Reduction surgery requires a general anaesthetic, with the
        operation taking about 3 hours. It is usually performed as a short-stay
        procedure.
      </p>

      <p>
        There are many different techniques described for Breast Reduction, with
        nearly all techniques leaving the nipple-areola complex attached to the
        breast tissue. The majority of surgeons in Australia use the Wise
        pattern / inverted-T (inferior pedicle) technique, which was described
        by Robbins, a Melbourne Plastic Surgeon.
      </p>

      <p>
        This technique leaves a scar around the areola, vertically down from the
        areola and horizontally long the breast crease. The excess skin, fat and
        breast tissue are removed, and the nipple-areola complex is moved to a
        higher position based on an inferiorly-based pedicle.
      </p>

      <p>
        Most Breast Reductions remove 600-800g of breast tissue on each side,
        but >1kg on each side is occasionally needed.
      </p>

      <p>
        Dr. Sellars can also use a vertical pattern type of reduction, which
        minimises the scars under the breast. This technique however, has a
        higher need for revisional surgery and works best smaller reductions in
        the younger patient. Dr Sellars will discuss whether this type of
        procedure is suitable for you at your consultation.
      </p>
      <Link to="/contact/">
        <Button inverted={true}>Contact us today!</Button>
      </Link>
    </Content>

    <BackgroundSection
      image={data.otherBreast.childImageSharp}
      title={content.backgroundTitle}
      height="500px"
      darkness="0.4"
    >
      <p style={{ fontSize: 18, margin: '-1rem 0 2rem' }}>
        Browse Dr. Graham Sellars full range of surgical and non-surgical procedures.
      </p>
      {content.otherOptions.map(({ to, text }) => (
        <Flex mr={2} mb={3} style={{ float: 'left' }}>
          <Link to={to}>
            <Button small={true} color="#fff" outline={true}>
              {text}
            </Button>
          </Link>
        </Flex>
      ))}
    </BackgroundSection>

    <StyledTabs style={{ marginTop: 50 }}>
      <h2>Information about {content.title}</h2>
      <StyledTabList>
        <StyledTab>Pre operative care</StyledTab>
        <StyledTab>Surgery</StyledTab>
        <StyledTab>Post operative care</StyledTab>
        <StyledTab>Risks of Surgery</StyledTab>
      </StyledTabList>

      <TabPanel>
        <h4>
          A full breast history and examination is essential prior to Breast
          Reduction surgery.
        </h4>
        <p>
          A preoperative mammogram is obtained in older patients and patients
          with significant breast history.
        </p>
        <p>
          Pre-operative consultation with the patient and partner (or parents)
          on at least two separate occasions is essential to ensure realistic
          expectations of the surgery. This involves showing photos of other
          patients who have undergone the surgery, giving reading material on
          the surgery.
        </p>
        <p>
          It is important to discuss the factors affecting the surgery,
          including the patient’s age and skin elasticity, and the size and
          shape of the patient’s breasts. The size and position of the nipple
          and areola, and an estimate of final cup size is discussed, although
          an exact cup size cannot be guaranteed.
        </p>
      </TabPanel>
      <TabPanel>
        <h4>
          Breast Reduction surgery requires a general anaesthetic, with the
          operation taking about 3 hours. It is usually performed as a
          short-stay procedure.
        </h4>

        <p>
          There are many different techniques described for Breast Reduction,
          with nearly all techniques leaving the nipple-areola complex attached
          to the breast tissue. The majority of surgeons in Australia use the
          Wise pattern / inverted-T (inferior pedicle) technique, which was
          described by Robbins, a Melbourne Plastic Surgeon.
        </p>

        <p>
          This technique leaves a scar around the areola, vertically down from
          the areola and horizontally long the breast crease. The excess skin,
          fat and breast tissue are removed, and the nipple-areola complex is
          moved to a higher position based on an inferiorly-based pedicle.
        </p>

        <p>
          Most Breast Reductions remove 600-800g of breast tissue on each side,
          but more than 1kg on each side is occasionally needed.
        </p>

        <p>
          Dr. Sellars can also use a vertical pattern type of reduction, which
          minimises the scars under the breast. This technique however, has a
          higher need for revisional surgery and works best smaller reductions
          in the younger patient. Dr Sellars will discuss whether this type of
          procedure is suitable for you at your consultation.
        </p>
      </TabPanel>
      <TabPanel>
        <h4>
          Following the surgery it is important to have restricted light
          activities, with no lifting or heavy duties for 10-14 days.
        </h4>
        <p>
          Most patients may drive again and return to work within two weeks
          after the surgery. Heavy activities and sport should not be undertaken
          for one month following the surgery.
        </p>

        <p>
          The breasts tend to settle in the months after the surgery, with the
          final breast shape not being attained until 3-6 months after surgery.
        </p>

        <p>
          The pain associated with Breast Reduction surgery varies from person
          to person but in general it’s not considered to be particularly
          painful. There may be pain for the first 24 hours and discomfort for a
          week or more. We will ensure that you have adequate pain medication,
          however most patients cope extremely well with minimal reliance on
          medication.
        </p>

        <p>
          Following reduction with Breast Lift you will have dressings covering
          incision sites which will restrict your ability to shower in the first
          week following surgery. With some extensive procedures, a small tube
          may be placed in each breast to drain off blood and fluids for the
          first day or two. A compression bra is not required to be worn;
          however, dressings will need to be changed during the first week.
        </p>
      </TabPanel>
      <TabPanel>
        <h4>Possible risks of any surgery include:</h4>
        <ul>
          <li>Wound infection</li>
          <li>Pain and discomfort</li>
          <li>Nausea</li>
          <li>Haematoma (an accumulation of blood around the surgical site that may require drainage)</li>
          <li>Heavy bleeding from the incisions</li>
          <li>Keloid or hypertrophic scars</li>
          <li>Slow healing</li>
          <li>Separation of the wound edges</li>
          <li>Allergies to anaesthetic agents, antiseptics, suture material or dressings</li>
          <li>Deep vein thrombosis DVT (blood clot in the leg)</li>
        </ul>

        <h4>Specific breast reduction risks:</h4>
        <ul>
          <li>Haematoma or seroma (collection of blood or fluid) that may require draining</li>
          <li>Asymmetry: the surgeon cannot guarantee symmetry of breast size and shape. Differences are likely to occur. The position of the nipples may vary slightly</li>
          <li>Loss of sensation in nipple and areolar. This is usually temporary</li>
          <li>Loss of nipple or areolar tissue. In rare cases the nipple and areolar can lose its blood supply and die. Reconstruction of a new nipple can be done at a later stage</li>
          <li>Breastfeeding – Breast lift does not usually affect the ability to breastfeed, however breast reduction is a more extensive surgery and breastfeeding may not be possible afterwards</li>
        </ul>
      </TabPanel>
    </StyledTabs>

    <BeforeAfter>
      <h2>Before &amp; After</h2>
      <div>
        {data.allFile.edges.map(
          image =>
            image.node.childImageSharp && (
              <Img resolutions={image.node.childImageSharp.resolutions} />
            )
        )}
      </div>
      <Flex justifyContent="center">
        <Link to="/gallery">
          <Button inverted={true}>See the full gallery</Button>
        </Link>
      </Flex>
    </BeforeAfter>

    <AboutContact data={data} />
  </MainLayout>
)
export const query = graphql`
  query BreastReductionQuery {
    allFile(filter: { relativeDirectory: { eq: "breast-reduction" } }) {
      edges {
        node {
          childImageSharp {
            resolutions(width: 500, height: 300, toFormat: JPG) {
              ...GatsbyImageSharpResolutions
            }
          }
        }
      }
    }
    womanWaterfall: file(relativePath: { eq: "background-generic.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    otherBreast: file(relativePath: { eq: "woods-woman.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    asaps: file(relativePath: { eq: "ASAPS.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    asps: file(relativePath: { eq: "ASPS.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    drSellars: file(relativePath: { eq: "dr-graham-sellars.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    fracs: file(relativePath: { eq: "FRACS.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
